import React from 'react';

const EditorIcon3 = ({ onClick }) => {
    return (
        <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 512 512"
     onClick={onClick}
            style={{ 
                cursor: 'pointer', // Optional: Changes cursor to pointer to indicate it's clickable
                width: '200px',   // Sets the width of the SVG
                height: '200px'   // Sets the height of the SVG
            }}
     >
<g>
	<g>
		<path d="M493.526,31.67H18.474C8.288,31.67,0,39.958,0,50.144v411.711c0,10.186,8.288,18.474,18.474,18.474h475.052
			c10.186,0,18.474-8.288,18.474-18.474V50.144C512,39.958,503.712,31.67,493.526,31.67z M15.835,110.845h63.34v58.062h-63.34
			V110.845z M15.835,184.742h63.34v58.062h-63.34V184.742z M15.835,258.639h63.34v58.062h-63.34V258.639z M15.835,332.536h63.34
			v58.062h-63.34V332.536z M496.165,461.856c0,1.43-1.209,2.639-2.639,2.639H18.474c-1.43,0-2.639-1.209-2.639-2.639v-55.423h480.33
			V461.856z M163.629,184.742v58.062H95.01v-58.062H163.629z M95.01,168.907v-58.062h68.619v58.062H95.01z M163.629,258.639v58.062
			H95.01v-58.062H163.629z M163.629,332.536v58.062H95.01v-58.062H163.629z M248.082,184.742v58.062h-68.619v-58.062H248.082z
			 M179.464,168.907v-58.062h68.619v58.062H179.464z M248.082,258.639v58.062h-68.619v-58.062H248.082z M248.082,332.536v58.062
			h-68.619v-58.062H248.082z M332.536,184.742v58.062h-68.619v-58.062H332.536z M263.918,168.907v-58.062h68.619v58.062H263.918z
			 M332.536,258.639v58.062h-68.619v-58.062H332.536z M332.536,332.536v58.062h-68.619v-58.062H332.536z M416.99,184.742v58.062
			h-68.619v-58.062H416.99z M348.371,168.907v-58.062h68.619v58.062H348.371z M416.99,258.639v58.062h-68.619v-58.062H416.99z
			 M416.99,332.536v58.062h-68.619v-58.062H416.99z M496.165,390.598h-63.34v-58.062h63.34V390.598z M496.165,316.701h-63.34
			v-58.062h63.34V316.701z M496.165,242.804h-63.34v-58.062h63.34V242.804z M496.165,168.907h-63.34v-58.062h63.34V168.907z
			 M496.165,95.01H15.835V50.144c0-1.43,1.209-2.639,2.639-2.639h475.052c1.43,0,2.639,1.209,2.639,2.639V95.01z"/>
	</g>
</g>
<g>
	<g>
		<rect x="39.588" y="427.546" width="295.588" height="15.835"/>
	</g>
</g>
<g>
	<g>
		<rect x="356.289" y="427.546" width="116.124" height="15.835"/>
	</g>
</g>
</svg>
    );
};

export default EditorIcon3;
