import React from 'react';

const EditorIcon1 = ({ onClick }) => {
    return (
        <svg 
            viewBox="-3 0 64 64" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            fill="#000000"
            onClick={onClick}
            style={{ 
                cursor: 'pointer', // Optional: Changes cursor to pointer to indicate it's clickable
                width: '200px',   // Sets the width of the SVG
                height: '200px'   // Sets the height of the SVG
            }}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
                <title>Pictures</title> 
                <desc>Created with Sketch.</desc> 
                <defs></defs> 
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> 
                    <g id="Pictures" transform="translate(1.000000, 1.000000)" stroke="#6B6C6E" strokeWidth="2"> 
                        <path d="M10,52 L1,52 C0.4,52 0,51.6 0,51 L0,1 C0,0.4 0.4,0 1,0 L45,0 C45.6,0 46,0.4 46,1 L46,10"></path> 
                        <path d="M10,36 L5,36 C4.4,36 4,35.6 4,35 L4,5 C4,4.4 4.4,4 5,4 L41,4 C41.6,4 42,4.4 42,5 L42,10"></path> 
                        <path d="M3.8,34.9 C6.1,32.5 8.2,30.7 10,29.4"></path> 
                        <path d="M56,61 C56,61.6 55.6,62 55,62 L11,62 C10.4,62 10,61.6 10,61 L10,11 C10,10.4 10.4,10 11,10 L55,10 C55.6,10 56,10.4 56,11 L56,61 Z"></path> 
                        <path d="M52,45 C52,45.6 51.6,46 51,46 L15,46 C14.4,46 14,45.6 14,45 L14,15 C14,14.4 14.4,14 15,14 L51,14 C51.6,14 52,14.4 52,15 L52,45 Z"></path> 
                        <path d="M13.8,44.9 C19.7,38.7 24.9,36.5 24.9,36.5 C24.9,36.5 32,39.3 37.8,45.8"></path> 
                        <path d="M29.7,38.7 C33.5,35.2 40.6,32.3 40.6,32.3 C40.6,32.3 47.7,35.3 52,40.1"></path> 
                        <circle cx="24" cy="28" r="3"></circle> 
                    </g> 
                </g> 
            </g>
        </svg>
    );
};

export default EditorIcon1;
