import React, { useState, useEffect } from 'react';
import FileUploadComponent from './file_upload'; // Assuming it's in the same directory
import QuillComponent from './quill/quillTest'; // Assuming it's in the same directory
import CategorySelect from './micro_components/categorySelect';
import EditorSelect from './micro_components/editorSelect';
import CustomEditor from '../../custom_editor/CustomEditor';
import ElementBuilder from './elementBuilder';
import CenteredWrapper from './micro_components/centeredWrapper';
import Modal from './general/modal';


//function NewResourceComponent({post_type, typeName, chosenEditor})
function NewResourceComponent({postType, resource=null, resourceMeta=null}) {
    const [category_id, setCategoryId] = useState(resource?.category_id || ''); // This might need to be an integer
    const [title, setTitle] = useState(resource?.title ||'');
    const [description, setDescription] = useState(resource?.description ||'');
    const [post_type, setPostType] = useState(postType.id); // This might need to be an integer
    const [typeName, setTypeName] = useState(postType.name);
    const [metaInfo, setMetaInfo] = useState(resourceMeta || []);
    const [classNames, setClassNames] = useState([]);
    const [status, setStatus] = useState(resource?.status ||'draft'); // Default status
    const [isPrivate, setIsPrivate] = useState(false); // Default to public
    const [editor, setEditor] = useState(resource?.editor_used || 0); // This will be the editor instance from the editor library you choose to use


    const [editorContent, setEditorContent] = useState('');
    const [prevEditorContent, setPrevEditorContent] = useState(''); // This will be the editor instance from the editor library you choose to use
    const [editors, setEditors] = useState([]);
    

    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const toggleUploadModal = (e) => {
        setUploadModalOpen(!uploadModalOpen);
        
    }


    useEffect(() => {
         setTimeout(() => {
         
        let textRetriever = (resourceMeta) ? document.querySelector(`.resource-elements`).outerHTML : document.querySelector(`.resource-elements`).outerHTML;
      
        
            const safeTextRetriever = textRetriever ? textRetriever : ""
            console.log("ELEMENTID", safeTextRetriever)
        setEditorContent(safeTextRetriever);

            }, 500);
    },[])




    const appendToMetaInfo = (newItem) => {
        setMetaInfo(prevMetaInfo => [...prevMetaInfo, newItem]);
       
    }

     useEffect(() => {
        fetch(`/api/editors-by-post-type?postTypeID=${postType.id}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setEditors(data.data);
                    console.log("nw r: ", data.data)
                } else {
                    console.error('Failed to fetch editors');
                }
            })
            .catch(error => console.error('Error fetching editors:', error));
    }, [postType.id]);

    const handleEditorSelect = (editorId) => {
        setEditor(editorId[0]);
    };








    const handleSubmit = () => {
        // Validation or conversion to proper types should be done here
        
        fetch((resource ? '/api/update-resource':'/api/insert-new-resource'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({resource_id: resource?.id || null,
                category_id: parseInt(category_id, 10), // Convert to integer
                title,
                description,
                post_type: parseInt(post_type, 10), // Convert to integer
                typeName,
                metaInfo,
                classNames,
                status, // Include status in the request
                isPrivate, // Include isPrivate in the request
                editor_used: editor
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                alert('Resource inserted successfully with ID: ' + data.resourceId);
            } else {
                alert('Error inserting resource.');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Error: ' + error);
        });
    };


    const ChooseEditor = ({ editor }) => { 
            console.log("CHOOSING", editor)
        if (editor === 1) {
            return(
            <FileUploadComponent onAppendMetaInfo={appendToMetaInfo}  />)
        }
        else if (editor === 2) { 
            if (editorContent !== prevEditorContent) {
                setPrevEditorContent(editorContent);
                setEditorContent(editorContent);
                            return (
            <QuillComponent editorContent={editorContent} setEditorContent={setEditorContent} metaInfo={metaInfo} setMetaInfo={setMetaInfo}/>
            )
            
            }

        
            return(  <QuillComponent editorContent={editorContent}  setEditorContent={setEditorContent} metaInfo={metaInfo} setMetaInfo={setMetaInfo}/>)

        }
                else if (editor===3){
<CustomEditor/>
        }
}





    if (editor===3)
    return(
<CustomEditor/>
        )


    return (
        <>
 { (resourceMeta) ?
<div style={{display: 'none'}}>
                <ElementBuilder jsonData={resourceMeta} />
     </div>  : null}


            <CenteredWrapper>
        

<div>
            <h2>  {typeName}</h2>

            {editor === 0 ? (
                <div>
                    <h2>Select an Editor</h2>
                    <EditorSelect chooseOnlyOne={true} onEditorChange={handleEditorSelect} givenEditors={editors}/>
                </div>
            ) : (

                        
                

                <div>
                    
                    <h3>Title</h3>
                     <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
       
            
                
            
                    <ChooseEditor editor={editor} />

                            <h3>Description</h3>
                     <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>       
                    
                            <button  onClick={toggleUploadModal}>Open publish</button>
                    </div>)}
                
            </div>
        <Modal isOpen={uploadModalOpen} onClose={toggleUploadModal}>

               <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <div className='resource-options'>
                    <div>
                    <label className='small-label'> Category</label>
                <CategorySelect categoryId={category_id} onCategoryChange={setCategoryId} hasNull={false} />
                    </div>
                    <div>
                        <label className='small-label'>Is private</label>
                <input type="checkbox" checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />
                    </div>
                    <div>
                        <label className='small-label'>Status</label>
                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="draft">Draft</option>
                        <option value="published">Published</option>
                        {/* Add other status options as needed */}
                    </select>
                    </div>
                </div>
             

                {/* Submit Button */}
                <button onClick={handleSubmit}>Submit</button>


          </Modal>
                </CenteredWrapper>
            </>
    );
}

export default NewResourceComponent;
