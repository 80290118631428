import React from 'react';

const EditorIcon2 = ({ onClick }) => {
    return (
        <svg 
            viewBox="0 0 16 16" 
            xmlns="http://www.w3.org/2000/svg" 
            fill="#000000"
            onClick={onClick}
            style={{ 
                cursor: 'pointer', // Optional: Changes cursor to pointer to indicate it's clickable
                width: '200px',   // Sets the width of the SVG
                height: '200px'   // Sets the height of the SVG
            }}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
                <path d="M0 0v16h9.75L14 11.75V6.28l-1 .986V11H9v4H1V1h11.254L10.28 3H3v1h6.295l-.986 1H3v1h4.322l-.986 1H3v1h2.352l-.987 1H3v1h.62c-.102.183-.148.243-.317.588-.314.638-.7 1.51-1.012 2.492L2 14l.92-.291a18.163 18.163 0 0 0 2.492-1.012c.638-.313.987-.52 1.088-.584l.045-.029L16 2.762l-2-2V0h-.762zm4.89 9.89l1.22 1.22-.178.175c.007-.005-.379.227-.961.514-.215.105-.536.222-.834.338l-.274-.274c.116-.298.233-.62.338-.834.287-.582.518-.966.514-.96z" style={{ marker: 'none', color: '#000000', overflow: 'visible', fill: 'gray' }}></path>
            </g>
        </svg>
    );
};

export default EditorIcon2;
