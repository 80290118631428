import React, { useState } from 'react';

function FileUploadComponent({ onAppendMetaInfo, type, onImageSelect, buttonText="Upload" }) {
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);


    const onFileChange = (e) => {
        setFile(e.target.files[0]);

    };

    const onFilenameChange = (e) => {
        setFilename(e.target.value);
    };

    const onPrivateChange = (e) => {
        setIsPrivate(e.target.checked);
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return 'pdf';
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
            return 'image';
        }
        return 'unknown';
    };

    const handleOnImageSelect = (givenID, givenPath) => {
        console.log("test: ", { id: givenID, path: givenPath})
        onImageSelect ?
            onImageSelect({ ID: givenID, path: givenPath}) : 
            console.log("no onImageSelect function passed to FileUploadComponent component")

     }
    
    const createMetaObject = (fileId) => {
        // Construct the meta objectv
        const fileType = getFileType(file.name);
        if (fileType === 'unknown') {
        throw new Error("only takes images or pdfs");
    }
    console.log("fileid: ",fileId)
    const metaObject = {
        ID: null, // This will be auto-incremented by the database
        resource_id: null, // You might need to provide this value based on your application's logic
        fileID: fileId,
        ordering: 0, // Default value, change as needed
        html_element: fileType === 'pdf' ? '???' :'img' , // Provide a value based on your application's logic
        number_of_children: 0,
        specific_style: '', // Provide a value based on your application's logic
        content_type: fileType === 'pdf' ? '???' :'img' , // Provide a value based on your application's logic
        content_data: '', // Provide a value based on your application's logic
        instruction: fileType === 'pdf' ? 'PDF' : fileType === 'image' ? 'IMAGE' : 'Unknown file type.' // Provide a value based on your application's logic
    };

    // Append the meta object using the function passed from the parent component
    onAppendMetaInfo(metaObject);
}


    const uploadFile = () => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        if (filename) {
        formData.append('filename', filename);
        } else {
        formData.append('filename', file.name); // Use original file name if no custom name is provided
        }
        formData.append('private', isPrivate); 
        formData.append('file', file);

        fetch('/api/upload', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
            .then(data => {
            console.log("data:",data)
            if (data.success) {
  
                
                alert('File uploaded successfully with ID: ' + data.fileId.id);
                createMetaObject(data.fileId.id)
                handleOnImageSelect(data.fileId.id,data.fileId.path) 
            } else {
                alert('Error uploading file.');
            }
        })

        .catch(error => {
            alert('Error uploading file: ' + error);
        });
    };

    const acceptedFileTypes = type === 'image' ? '.jpg, .jpeg, .png, .gif' :
                             type === 'pdf' ? '.pdf' : '';

    return (
        <div>
            <input type="text" placeholder="Enter desired filename prefix" value={filename} onChange={onFilenameChange} />
            <input type="file" onChange={onFileChange} accept={acceptedFileTypes} />
            <label>
                Make Private:
                <input type="checkbox" checked={isPrivate} onChange={onPrivateChange} />
            </label>
            <button onClick={uploadFile}>{buttonText}</button>
        </div>
    );
}


export default FileUploadComponent;
