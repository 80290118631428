import React from 'react';

const TextIcon = ({ onClick }) => {
    return (
        <svg
            viewBox="0 0 24 24" // Set the viewBox as per the SVG file
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            style={{ cursor: 'pointer', height: '30px', width: '30px', fill: 'inherit' }} // Adjust size as needed
        >
<path d="m9 22c-.414 0-.75-.336-.75-.75v-18.5c0-.414.336-.75.75-.75s.75.336.75.75v18.5c0 .414-.336.75-.75.75z"/><path d="m17.25 6c-.414 0-.75-.336-.75-.75v-1.75h-15v1.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-2.5c0-.414.336-.75.75-.75h16.5c.414 0 .75.336.75.75v2.5c0 .414-.336.75-.75.75z"/><path d="m11.25 22h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m14.75 14.25c-.414 0-.75-.336-.75-.75v-1.75c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.75h-7v1c0 .414-.336.75-.75.75z"/><path d="m19 22c-.414 0-.75-.336-.75-.75v-9.5c0-.414.336-.75.75-.75s.75.336.75.75v9.5c0 .414-.336.75-.75.75z"/><path d="m20.25 22h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/>
        </svg>
    );
};

export default TextIcon;
