import React from 'react';

const NoImageSVG = ({ onClick }) => {
    return (
        <svg 
            viewBox="15.325 15.166 70.148 70.881" 
            xmlns="http://www.w3.org/2000/svg" 
            onClick={onClick}
             style={{ 
                cursor: 'pointer', // Changes cursor to pointer to indicate it's clickable
                width: '200px',   // Sets the width of the SVG
                height: '200px'   // Sets the height of the SVG
            }} // Optional: Changes cursor to pointer to indicate it's clickable
        >
            <path d="M76.631,28.823l-30.909-8.282c-0.523-0.143-1.08,0.167-1.225,0.707l-4.017,14.99l-17.111,4.585  c-0.533,0.143-0.85,0.691-0.707,1.225l9.835,36.705c0.14,0.526,0.683,0.851,1.225,0.707l30.909-8.282  c0.533-0.143,0.85-0.691,0.707-1.225l-0.792-2.957l1.733,0.464c0.542,0.145,1.085-0.184,1.225-0.707l9.835-36.705  C77.48,29.514,77.164,28.966,76.631,28.823z M63.146,69.504L34.17,77.27l-9.317-34.773l15.03-4.027l-5.221,19.484  c-0.143,0.533,0.174,1.082,0.707,1.225l26.945,7.221L63.146,69.504z M65.83,65.27l-28.978-7.766l9.317-34.773l28.978,7.765  L65.83,65.27z M41.82,50.553l23.183,6.213c0.561,0.145,1.089-0.193,1.226-0.707l6.211-23.184c0.143-0.534-0.173-1.082-0.707-1.225  L48.55,25.439c-0.532-0.144-1.082,0.173-1.225,0.707l-6.212,23.182C40.969,49.874,41.296,50.411,41.82,50.553z M48.998,27.63  l21.251,5.694L67.25,44.515l-3.742-6.483c-0.275-0.475-0.885-0.645-1.365-0.366l-8.933,5.157l-2.358-4.084  c-0.276-0.478-0.886-0.643-1.366-0.366l-3.982,2.299L48.998,27.63z M44.771,43.405l4.848-2.8l2.357,4.084  c0.276,0.479,0.889,0.641,1.366,0.366l8.933-5.157l4.242,7.349l-1.963,7.327l-21.25-5.694L44.771,43.405z"/>
        </svg>
    );
};

export default NoImageSVG;
